import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pricing-header p-3 pb-md-4 mx-auto text-center" }
const _hoisted_2 = { class: "display-4 fw-normal" }
const _hoisted_3 = {
  key: 0,
  class: "fs-5 text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.subtitle), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}