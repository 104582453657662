import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Prism = _resolveComponent("Prism")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.language) = $event)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setCode && _ctx.setCode(...args))),
      class: "form-select"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (lang) => {
        return (_openBlock(), _createElementBlock("option", {
          key: lang.display,
          value: lang,
          selected: _ctx.language && _ctx.language.value == lang.value
        }, _toDisplayString(lang.display), 9, _hoisted_1))
      }), 128))
    ], 544), [
      [_vModelSelect, _ctx.language]
    ]),
    (_openBlock(), _createBlock(_component_Prism, {
      key: _ctx.code,
      language: _ctx.language.value
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.code), 1)
      ]),
      _: 1
    }, 8, ["language"]))
  ]))
}